<!--
 * @Author: lbh
 * @Date: 2021-04-28 18:49:27
 * @LastEditors: lbh
 * @LastEditTime: 2021-04-29 11:54:56
 * @Description: 別府集團
-->
<template>
  <div
    class="gsq-box t-body"
    :style="`min-height:${window.bodyH}px;`"
  >
    <selfHeaderN
      ad="case"
      child='biefu'
      :type="window.type"
    />
    <div
      class="s-body"
      :class="window.type=='1000' ? '' : 's-body_1'"
    >
      <selfBackground
        :backH="window.backH"
        title="熱門案例"
        :type="1"
        :wtype="window.type"
        :image="getImgPath('case.jpg')"
      />
      <selfGraphic
        :type="window.type"
        :imgs="graphic.imgs"
        :datas="graphic.datas"
        :height="graphic.height"
        title="別府飲食集團"
        :bImg="graphic.bImg"
        bImgP="left"
      />
    </div>
    <selfFooter :type="window.type" />
  </div>
</template>
<script>
import selfHeaderN from "@/components/header/headerN";
import selfFooter from "@/components/footer/footer";
import selfBackground from "@/components/commo/background";
import selfGraphic from "@/components/commo/graphic";
import util from "@/js/utils/util";
export default {
  components: {
    selfHeaderN,
    selfFooter,
    selfBackground,
    selfGraphic
  },
  data () {
    return {
      window: {
        type: '1000',
        bodyH: 0,
        backH: 0,
      },
      graphic: {
        imgs: [],
        datas: [],
        height: 0,
        bImg: ''
      },
      bgImage: ''
    }
  },
  mounted () {
    util.setTitle('別府飲食集團 – 薑軟件有限公司');
    // 初始化窗口
    this.initWindow();
    // 監聽 窗口
    window.onresize = () => {
      // 1000 700
      this.initWindow();
    };
    // 初始化數據
    let imgs = [
      util.getImgPath('Bepu-500x400.jpg'), 
      util.getImgPath('bepu02-600x400.png'), 
      util.getImgPath('bepu01-600x400.png')];
    this.graphic.imgs = imgs;
    let datas = [
      {
        title: "餐廳面對問題",
        items: [
          { title: '人手填寫會員資料, 實體卡損耗及缺乏主動營銷渠道', style: 'margin-top:25px' },
          { title: '', style: 'height:1px;background-color:#000;margin-top:35px;' }
        ]
      },
      {
        title: '解決方案', items: [
          { title: '產品名稱: 別府集團APP', style: 'margin-top:25px' },
          { title: '- 使用別府集團手機APP, SMS驗證, 會員簡易註冊, 與POS對接' },
          { title: '- 消費, 儲經驗值, 升級, 派優惠券, 儲分, 無紙化, 以及二次營銷' },
        ],
      },
    ];
    this.graphic.datas = datas;
    this.graphic.bImg = util.getImgPath('Bu_case-1.png');
  },
  methods: {
    initWindow () {
      let W = util.W(), w = W.w, h = W.h;
      this.window.bodyH = h;
      this.window.backH = parseInt(w / 5);
      if (w < 700) {
        this.window.type = '600';
        this.window.backH = w / 2.5;
        this.graphic.height = (w * 0.8 - 40) * 0.8;
      } else if (w < 1000) {
        this.window.type = '900';
        this.window.backH = w / 2.5;
        this.graphic.height = (w * 0.8 - 40) * 0.8;
      } else {
        this.window.type = '1000';
        this.graphic.height = (w * 0.8 / 2 - 40) * 0.8;
      }
    },
    getImgPath (t) {
      return util.getImgPath(t);
    }
  }
}
</script>
<style lang="less" scoped>
</style>
